<script>
import VLabel from 'vuetify/lib/components/VLabel';
import VTextField from 'vuetify/lib/components/VTextField';
import { keyCodes, getSlot } from 'vuetify/lib/util/helpers';
import sizeable from 'vuetify/lib/mixins/sizeable';
import measurable from 'vuetify/lib/mixins/measurable';
import { componentProps } from '@/utils';
import config from './defaults';
import '../input.scss';

const defaultProps = {
  ...VTextField.options.props,
  ...sizeable.options.props,
  ...measurable.options.props,
  prevent: Function,
  inputValue: null
};

export default {
  name: 'TextField',
  inheritAttrs: false,
  mixins: [ VTextField, sizeable, measurable ],
  props: componentProps( defaultProps, config ),
  data() {
    return {
      inputHeight: 0
    }
  },
  computed: {
    rootClasses() {
      return {
        'text-field': true,
        'sizeable-field': true,
        ...this.sizeableClasses
      };
    },
    externalLabel() {
      if ( this.singleLine ) return this.label;
      return '';
    },
    labelPosition() {
      return {
        paddingLeft: ( this.rounded
          ? this.prependWidth + this.inputHeight / 2
          : this.prependWidth
        ) + 'px'
      };
    }
  },
  watch: {
    inputValue( val ) {
      this.internalValue = val;
    },
    valid( value ) {
      this.$emit( 'valid', value );
    }
  },
  methods: {
    genExternalLabel() {
      return this.externalLabel && this.$createElement( 'label', {
        staticClass: 'caption',
        style: this.labelPosition
      },[ this.$slots.label || this.externalLabel ]);
    },
    genLabel() {
      if ( ! this.showLabel || this.externalLabel ) return null;
      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue
        }
      };
      return this.$createElement( VLabel, data, this.$slots.label || this.label );
    },
    onKeyDown(e) {

      if ( e.keyCode === keyCodes.enter && this.lazyValue !== this.initialValue ) {
        this.initialValue = this.lazyValue;
        this.$emit('change', this.initialValue);
      }

      this.$emit('keydown', e);

      if ( this.prevent && this.prevent(e)) {
        e.preventDefault();
        return false;
      }
    },
    setInputHeight() {
      if ( ! this.$refs.input ) return;
      this.inputHeight = this.$refs.input.clientHeight;
    }
  },
  updated() {
    this.$nextTick( this.setInputHeight );
  },
  beforeMount() {
    if ( this.inputValue != null )
      this.internalValue = this.inputValue;
  },
  mounted() {
    this.setInputHeight();
  },
  render( h, style ) {
    return h( 'div', {
      class: this.rootClasses,
      style: {
        ...this.measurableStyles,
        ...style
      }
    }, [
      this.genExternalLabel(),
      VTextField.options.render.call( this, h ),
      getSlot( this, 'extend', this, true )
    ]);
  }
}
</script>

<style lang="scss">
.text-field {
  .v-input {
    .v-text-field__slot {
      padding: 2px 0;
    }
    > .v-input__control > .v-input__slot > .v-input__append-inner,
    > .v-input__control > .v-input__slot > .v-input__prepend-inner {
      align-self: auto;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
